$(document).ready(function () {

  initLanguages();
	initStickySidebar();
	initMobileSearch();

});

function initLanguages() {
  $('.languages__current').on('click',function() {
		$('.languages__others').stop(true,true).toggleClass('visible');
	});
	$(document).on("click", function(e) {
		if (!e.target.closest('.header__languages') && !e.target.classList.contains('header__languages')) {
			$('.languages__others').removeClass('visible');
		}
	});
}

function initStickySidebar() {
	const sidebarExist = document.querySelector('#sticky-container');
  if (sidebarExist) {
		if ($(window).width() > 1024) {
			$('#sticky').sticky('#sticky-container', {
				useTransition: false,
				animate: false,
				offset: 30
			});
		}
	}
}

function initMobileSearch() {
	$('.header__form-trigger').on('click',function() {
		$('.header__form').stop(true,true).addClass('visible');
	});
	$('.header__form .form__close').on('click',function() {
		$('.header__form').stop(true,true).removeClass('visible');
	});
}
